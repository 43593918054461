<template>
  <v-container fluid>
    <v-card>
      <v-card-text class="pt-0 pb-0">
        <v-container fluid class="simpleContainer">
          <v-row>
            <v-col cols="2" class="titulo d-flex align-center" >
              <b class="print">Fluxo de Caixa </b>
            </v-col>

            <v-col cols="8" class="pt-0 pb-0">
              <dateArrows v-model="filtroDate" :disabled="isLoadingAccount" />
            </v-col>
            <v-col cols="1" class="pt-0 pb-0 d-flex align-center titulo">
                <span class="print" @click="eventPrint"> Imprimir </span>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>

<!-- MENU FILTROS -->
    <v-row class="pl-10 mt-2">
      <h4>Mostrando</h4>

      <v-menu bottom offset-y>
        <template
          v-slot:activator="{ on, attrs }">
          <h4 v-bind="attrs" v-on="on" :style="`margin-left: 4px; color: ${variables.colorPrimary}`">
            <u>{{selectedCashFlowFilterType.title}}</u>
          </h4>
        </template>

        <v-list>
          <v-list-item v-for="(item, index) in cashFlowFilterType" :key="index" @click="onSelectCashFlowFilterType(item)" link>
            <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>

      <h4 style="margin-left: 4px;"><u>de</u></h4>

      <v-menu :disabled="isLoadingAccount" bottom offset-y>
        <template
          v-slot:activator="{ on, attrs }">
          <h4 v-bind="attrs" v-on="on" :style="`margin-left: 4px; color: ${isLoadingAccount ? variables.colorAccent : variables.colorPrimary}`">
            <u> {{selectedFilterAccount.name}} </u>
          </h4>
        </template>

        <v-list>
          <v-list-item v-for="(item, index) in accountList" :key="index" @click="onSelectFilterAccount(item)" link>
            <v-list-item-title>{{item.name}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-row>

    <v-skeleton-loader type="table print" :loading="isLoadingAccount">
      <v-card style="margin-top: 16px;">
        <v-card-text class="mt-0 pt-0 pb-0 mb-0 pl-0 pr-0">
          <v-container fluid>
            <v-row class="table print">
              <v-col class="table-labels pl-0 pb-0 pr-0 pt-0">
                <v-row no-gutters justify="center" class="pl-1 pr-1" style="border-bottom: 1px solid #ccc;">
                  <h4 class="text-center"> - </h4>
                </v-row>

                <v-row class="balance-item-column default saldo  balance-item-column-balance" no-gutters style="">
                  <h4>Saldo Anterior</h4>
                </v-row>

                <v-row v-if="selectedCashFlowFilterType.value" class="balance-item-column default abc" no-gutters>
                  <h4>Saldo Anterior Previsto</h4>
                </v-row>

                <v-row class="balance-item-column default balance-item-column-balance saldo-atual" no-gutters>
                  <h4>Resultado</h4>
                </v-row>

                <v-row v-if="selectedCashFlowFilterType.value" class="balance-item-column default balance-item-column-balance abc" no-gutters>
                  <h4>Saldo Anterior Previsto</h4>
                </v-row>

                <v-row class="balance-item-column default balance-item-column-balance saldo-total" no-gutters>
                  <h4>SALDO ATUAL </h4>
                </v-row>
                
                <!-- Gambis para despesas com 3 niveis -->
                <v-row class="balance-item-column balance-item-column-outbound" no-gutters>
                  <!-- <h4>{{ item.name }}</h4> -->
                  <v-expansion-panels v-model="painelDespesas" tile flat multiple accordion class="panel-labels-niveis">
                    <v-expansion-panel v-for="item in (0, 1)" :key="item" >
                      <v-expansion-panel-header>
                        <template v-slot:actions>
                          <v-icon> $expand </v-icon>
                        </template>

                        <h4>{{ 'Despesas' }}</h4>

                        <h5 v-if="selectedCashFlowFilterType.value">Previsto</h5>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        
                      <v-expansion-panel class="nivel2" v-for="item in balanceList[0] ? balanceList[0].val.despesas : []" :key="item.id">
                        <v-expansion-panel-header>
                          <template v-slot:actions>
                            <v-icon class="ml-3"> $expand </v-icon>
                          </template>
                          <h4 class="pl-8"> {{ item.name }}</h4>
                          <h5 v-if="selectedCashFlowFilterType.value">Previsto</h5>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <template v-for="subItem in item.children">
                            <h4 class="pl-8" :key="subItem.id"> {{ subItem.name }} </h4>
                            <h5 v-if="selectedCashFlowFilterType.value" :key="subItem.id + 'A'">Previsto</h5>
                          </template>
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>


                <v-row class="balance-item-column balance-item-column-outbound"  no-gutters>
                  <!-- <h4>{{ item.name }}</h4> -->
                  <v-expansion-panels v-model="panelReceitas" tile flat multiple accordion class="panel-labels-niveis">
                    <v-expansion-panel v-for="item in balanceList[0] ? balanceList[0].val.receitas : []" :key="item.id">
                      <v-expansion-panel-header style="background: #eeeeee">
                        <template v-slot:actions>
                          <v-icon>
                            $expand
                          </v-icon>
                        </template>
                        <h4>{{ item.name }}</h4>

                        <h5 v-if="selectedCashFlowFilterType.value">Previsto</h5>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content >
                        <template v-for="subItem in item.children">
                          <h4 :key="subItem.id">{{ subItem.name }}</h4>
                          <h5 v-if="selectedCashFlowFilterType.value" :key="subItem.id + 'A'"> Previsto </h5>
                        </template>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>

                <!-- <v-row class="balance-item-column default balance-item-column-incoming" no-gutters>
                  <h4>Total Receitas</h4>
                </v-row> -->

                <v-row v-if="selectedCashFlowFilterType.value" class="balance-item-column balance-item-column-incoming" no-gutters>
                  <h4>Total Receitas Prevista</h4>
                </v-row>


                <v-row class="balance-item-column balance-item-column-outbound" no-gutters>
                  <!-- <h4>{{ item.name }}</h4> -->

                  <v-expansion-panels v-model="panelCusteio" tile flat multiple accordion class="panel-labels-niveis" >
                    <v-expansion-panel
                      v-for="item in balanceList[0] ? balanceList[0].val.custeio : []"
                      :key="item.id"
                    >
                      <v-expansion-panel-header class="custeio">
                        <template v-slot:actions>
                          <v-icon> $expand </v-icon>
                        </template>
                        <h4> {{ item.name }}  </h4>
                        <h5 v-if="selectedCashFlowFilterType.value">Previsto</h5>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <template v-for="subItem in item.children">
                          <h4 :key="subItem.id">{{ subItem.name }}</h4>
                          <h5 v-if="selectedCashFlowFilterType.value" :key="subItem.id + 'A'">Previsto</h5>
                        </template>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>

                 <!-- <v-row class="balance-item-column balance-item-column-costing" no-gutters style="margin-top: 16px;">
                  <h4>Saldo de Custeio</h4>
                </v-row> -->

                <v-row v-if="selectedCashFlowFilterType.value" class="balance-item-column balance-item-column-costing" no-gutters>
                  <h4>Saldo de Custeio Prevista</h4>
                </v-row>

                <!-- TOTAL R D C -->
                 <!-- <v-row class="balance-item-column default balance-item-column-balance saldo-total abc" no-gutters>
                  <h4> TOTAL </h4>
                </v-row> -->

                <v-row class="balance-item-column default total balance-item-column-outbound" no-gutters style="">
                  <!-- <h4>Saldo de Despesas</h4> -->
                </v-row>

                <v-row v-if="selectedCashFlowFilterType.value" class="balance-item-column balance-item-column-outbound" no-gutters>
                  <h4>Saldo de Despesas Prevista</h4>
                </v-row>
              </v-col>

              <div class="tabela-valores">
                <v-row class="pl-0" no-gutters :style="`min-width: ${135*balanceList.length}px;`">
                  <v-col v-for="(item, index) in balanceList" :key="index" style="max-width: 135px; border: 1px solid #ccc" class="pl-0 pr-0">
                    <v-row no-gutters justify="start" class="pl-3 pr-1 balance-item-column header" style="border-bottom: 1px solid #ccc;">
                      <h3>{{ item.cabecalho }}</h3>
                    </v-row>

                    <!-- Saldo Anterior -->
                    <v-row class="balance-item-column border saldo pl-3 justify-end pr-3" no-gutters >
                      <h4 :style="`${defaultColor(item.val.saldo_anterior)}`">{{item.val.saldo_anterior | currency}}</h4>
                    </v-row>

                    <!-- Saldo Anterior Previsto -->
                    <v-row v-if="selectedCashFlowFilterType.value" class="balance-item-column border justify-end pr-3" no-gutters>
                      <h4>{{item.val.saldo_anterior_previsto | currency}}</h4>
                    </v-row>

                    <!-- Saldo Atual -->
                    <v-row class="balance-item-column border saldo-atual justify-end pr-3" no-gutters>
                      <h4  :style="`${defaultColor(item.val.saldo_atual)}`">{{item.val.saldo_atual | currency}}</h4>
                    </v-row>

                    <!-- Saldo Atual Previsto -->
                    <v-row v-if="selectedCashFlowFilterType.value" class="balance-item-column border pl-3" no-gutters>
                      <h4>{{item.val.saldo_atual_previsto | currency}}</h4>
                    </v-row>

                      <!-- Novo total -->
                    <v-row class="balance-item-column justify-end pr-3" no-gutters style="">
                      <h4 :style="`${defaultColor(item.val.saldo_liquido_periodo, 'total')}`">{{item.val.saldo_liquido_periodo | currency}}</h4>
                    </v-row>

                    <v-divider/>

                   <!-- Linhas -->
                <v-row class="balance-item-column balance-item-column-outbound striped" no-gutters>
                  <!-- <h4>{{ item.name }}</h4> -->
                  <v-expansion-panels v-model="painelDespesas" tile flat multiple accordion class="panel-dados">
                    <v-expansion-panel v-for="a in (0, 1)" :key="a" >
                      <v-expansion-panel-header style="height: 22px;" class="pl-3">
                        <template v-slot:actions>
                          <v-icon></v-icon>
                        </template>

                        <h4 class="text-right pr-3" :style="`${defaultColor( item.data.totalGeral || 0)}`"> {{ item.data.totalGeral| currency }}  </h4>
                        <h5 v-if="selectedCashFlowFilterType.value">Previsto</h5>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        
                    <v-expansion-panel v-for="it in item.val.despesas" :key="it.id" >
                      <v-expansion-panel-header>
                        <template v-slot:actions>
                          <v-icon></v-icon>

                        </template>
                        <h4 :style="`${defaultColor(it.value)}`" > {{ it.value | currency }}   </h4>
                        <h5 v-if="selectedCashFlowFilterType.value">Previsto</h5>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content >
                        <template v-for="subItem in it.children">
                          <h4 :style="`${defaultColor(subItem.value)}`" :key="subItem.id"> {{ subItem.value | currency }}  </h4>
                          <h5 v-if="selectedCashFlowFilterType.value" :key="subItem.id + 'A'">Previsto</h5>
                        </template>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                        <!-- <template v-for="subItem in item.children">
                          <h4 :key="subItem.id">{{ subItem.name }}</h4>
                          <h5 v-if="selectedCashFlowFilterType.value" :key="subItem.id + 'A'"> Previsto </h5>
                        </template> -->

                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>

                <v-row class="balance-item-column striped" no-gutters>
                  <v-expansion-panels v-model="panelReceitas" tile flat multiple accordion class="panel-dados">
                    <v-expansion-panel v-for="receitas in item.val.receitas" :key="receitas.id" >

                      <v-expansion-panel-header style="height: 22px; background: #eeeeee" class=" ">
                        <template v-slot:actions>
                          <v-icon></v-icon>
                        </template>
                        <h4 class="text-right pr-3" :style="`${defaultColor(item.val.saldo_receitas)}`"> {{receitas.value | currency}}  </h4>
                        <h5 v-if="selectedCashFlowFilterType.value" :style="`${defaultColor(item.val.saldo_receitas)}`">{{receitas.provided | currency}} </h5>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <template v-for="subItem in receitas.children">
                          <h4 :style="`${defaultColor(item.val.saldo_receitas)}`" :key="subItem.id">{{ subItem.value | currency }} </h4>
                          <h5 v-if="selectedCashFlowFilterType.value" :key="subItem.id + 'A'">{{ subItem.provided | currency }}</h5>
                        </template>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>

                    <!-- Total Receitas 
                        <v-row class="balance-item-column" no-gutters>
                          <h4 :style="item.val.saldo_receitas <= 0 ? 'color: red;' : `color: ${variables.colorGreenDarken};`">{{item.val.saldo_receitas | currency}}</h4>
                        </v-row>

                        <v-row v-if="selectedCashFlowFilterType.value" class="balance-item-column" no-gutters>
                          <h4>{{item.val.saldo_receitas_previstas | currency}}</h4>
                        </v-row>
                    -->

                  <!-- #CUSTEIO -->
                    <v-row class="balance-item-column" no-gutters>
                      <v-expansion-panels v-model="panelCusteio" tile flat multiple accordion class="panel-dados">
                        <v-expansion-panel
                          v-for="custeio in item.val.custeio"
                          :key="custeio.id"
                        >
                          <v-expansion-panel-header style="height: 22px;" class="pr-3 custeio">
                            <template v-slot:actions>
                              <v-icon></v-icon>
                            </template>
                            <h4 class="text-right" :style="`${defaultColor(custeio.value, 'custeio')}`">{{custeio.value | currency}} </h4>
                            <h5 v-if="selectedCashFlowFilterType.value" :style="`${defaultColor(custeio.provided)}`">{{custeio.provided | currency}}</h5>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <template v-for="subItem in custeio.children">
                              <h4 :key="subItem.id" :style="`${defaultColor(subItem.value, 'custeio')}`">{{ subItem.value | currency }}</h4>
                              <h5 v-if="selectedCashFlowFilterType.value" :key="subItem.id + 'A'">{{ subItem.provided | currency }}</h5>
                            </template>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                      
                    </v-row>

                    <!-- <v-row class="balance-item-column" no-gutters style="margin-top: 16px;">
                      <h4 :style="item.val.saldo_custeio <= 0 ? 'color: red;' : `color: ${variables.colorGreenDarken};`">{{item.val.saldo_custeio | currency}}</h4>
                    </v-row> -->

                    <v-row v-if="selectedCashFlowFilterType.value" class="balance-item-column pl-3" no-gutters>
                      <h4>{{item.val.saldo_custeio_previstas | currency}}</h4>
                    </v-row>

                    <!-- <v-row class="balance-item-column" no-gutters style="">
                      <v-expansion-panels v-model="panelDespesas" tile flat multiple accordion class="panel-dados">
                        <v-expansion-panel
                          v-for="despesas in item.val.despesas"
                          :key="despesas.id"
                        >
                          <v-expansion-panel-header style=" height: 22px;" class="pl-3">
                            <template v-slot:actions>
                              <v-icon></v-icon>
                            </template>
                            <h4 :style="`${defaultColor(despesas.value)}`">{{despesas.value | currency}}</h4>
                            <h5 v-if="selectedCashFlowFilterType.value" :style="`${defaultColor(despesas.provided)}`">{{despesas.provided | currency}}</h5>
                          </v-expansion-panel-header>

                          <v-expansion-panel-content>
                            <template v-for="subItem in despesas.children">
                              <h4 :key="subItem.id">{{ subItem.value | currency }} </h4>
                              <h5 v-if="selectedCashFlowFilterType.value" :key="subItem.id + 'A'">{{ subItem.provided | currency }}</h5>
                            </template>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                      
                    </v-row> -->
                    <v-row v-if="selectedCashFlowFilterType.value" class="balance-item-column" no-gutters>
                      <h4>{{item.val.saldo_despesas_previstas | currency}}</h4>
                    </v-row>

                    <!-- TOTAL -->
                    <!-- <v-row class="balance-item-column border saldo-total-bug pl-3 justify-end pr-3" no-gutters >
                      <h4 :style="`${defaultColor(item.data.DRC, 'total')}`">{{item.data.DRC | currency}}</h4>
                    </v-row> -->
                   <!-- <v-row class="balance-item-column border saldo-total justify-end pr-3" no-gutters>
                      <h4 :style="`${defaultColor(item.data.DRC, 'total')}`">{{ item.data.DRC | currencyEqual}}</h4>
                    </v-row> -->

                  </v-col>
                </v-row>
              </div>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-skeleton-loader>
   
  </v-container>
</template>

<script>
import { cashFlowFilterType } from '@/utils/tempUtils'
import variables from '@/assets/styles/helpers/_variables.scss'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'
import dateArrows from '@/views/components/dateArrows'

const namespaceStore = 'organizzeRelatoriosFluxoCaixa'

export default {
  name: 'OrganizzeRelatoriosFluxoCaixa',
  props: {},
  directives: {},
  components: {
    // OrganizzeCalendar: () => import ('@/views/components/organizze/calendar/index'),
    // OrganizzeScrollbar: () => import('@/views/components/organizze/scrollbar'),
    dateArrows
  },
  data: () => ({
    isLoadingAccount: false,
    canShowForecast: false,
    selectedCashFlowFilterType: {},
    selectedFilterAccount: { id: null, name: 'Todos' },
    startDate: moment().format('YYYY-MM-DD'),
    endDate: moment().format('YYYY-MM-DD'),
    loading: false,
    panelDespesas: [],
    panelReceitas: [],
    panelCusteio: [],
    painelDespesas: [],
    subPanel: [],
    filtroDate: { text: ''} 
  }),
  created () {},
  mounted () {
    const self = this
    self.selectedCashFlowFilterType = cashFlowFilterType[0]
    self.filtroDate.text = moment().year()
    self.buscarContas()
    // self.buscar()
  },
  computed: {
    ...mapGetters(namespaceStore, ['accountList', 'balanceList', 'headers']),
    cashFlowFilterType: () => cashFlowFilterType,
    variables: () => variables
  },
  watch: {
    'filtroDate.text' (v) {
      this.buscar(v)
    }
  },
  methods: {
    ...mapActions(namespaceStore, ['getAccounts', 'getBalances']),
    eventPrint () {
      console.log('', window.print()) 
    },
    defaultColor (v, pos = false) {
      let val = parseInt(v)
      if (pos === 'total') return `color: #1cb4ff;`
      if (pos === 'custeio' && val !== 0) return `color: #de890a;`
      if (val < 0 || (pos && val !== 0)) return `color: ${variables.colorGreenDarken};`
      if (val === 0) return `color: ${variables.colorAccent};`
      return 'color: red;'
    },
    onSelectCashFlowFilterType (item) {
      const self = this
      self.selectedCashFlowFilterType = item
    },

    buscarContas () {
      const self = this
      self.getAccounts().then(() => {
        self.accountList.unshift({ name: 'Todos', id: null })
      }).catch(e => { console.error(e) }).finally(() => {
      }).finally(() => {
        self.painelDespesas.push(0)
        self.panelCusteio.push(0)
        self.panelReceitas.push(0)
      })
    },

    onSelectFilterAccount (item) {
      const self = this
      self.selectedFilterAccount = item
      self.buscar(self.filtroDate.text, item.id)
    },

    syncDate (val) {
      const self = this
      self.startDate = val.startDate
      self.endDate = val.endDate
    },

    buscar (v, id) {
      const self = this

      self.isLoadingAccount = true
      console.log(v)
      const accountId = id || undefined
      self.getBalances({
        filterDtStart: moment(v, 'YYYY').startOf('years').format('YYYY-MM-DD'),
        filterDtEnd:  moment(v, 'YYYY').endOf('years').format('YYYY-MM-DD'),
        account: accountId }).then(() => {}).catch(e => {
        console.error(e)
      }).finally(() => { self.isLoadingAccount = false })
    }
  },
  validations () { return {} }
}
</script>

<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';
  @media print {
    .print{
      display:block;
    }
    .no-print{
      display: none;
    }
  }
  .container {
    overflow: hidden;
  }

  .tabela-valores{
    width: 84%;
    margin-top: 0px;
    overflow-x: auto;
  }
  .table {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    padding-left: 15px;
    &-labels {
      min-width: 290px !important;
      max-width: 290px !important;
      border: 1px solid #ccc;
      border-right: 1px solid #ccc;
      @media(max-width: 500px) {
        min-width: 225px !important;
        max-width: 225px !important;
      }
    }
  }

  %default {
    padding-left: 10px;
    font-size: 12px !important;
    color: #9797ad;
    @media(max-width: 500px) {
      font-size: 9px !important;
    }
  }

  .btnBuscar {
    margin-left: 16px;
    align-self: center;
    color: #FFFFFF;
    background-color: $colorPrimary !important;

    &:hover {
      color: #000000;
    }
  }

  .balance-item-column {
    font-size: 12px;
    justify-content: start;
    color: $colorAccent;
    &:nth-child(even) {
      background: #eeeeee ;
    }

    &.header {
      color: darkcyan;
      font-weight: bold;
      h3 {
        font-size: 14px !important;
        text-align: right;
        padding-right: 10px;
        width: 100%;
        text-transform: uppercase;

      }
    }
    &.border {
      border-bottom: 1px solid #ccc;
    }
    &-abc {
      &:nth-child(even) {
        background: #eeeeee !important;
      }
    }
    &.default {
      @extend %default;
    }
    &.saldo {
      background: #e2dddd !important;
      &-atual {
        background: #eeffdd;
      }
      &-total {
        color: #1cb4ff !important;
        &-bug {
          color: #1cb4ff !important;
          background: #eeeeee;
        }
      }
    }

    &-balance {
      color: $colorGreenDarken;
      font-size: 12px;
      text-transform: uppercase;
      border-bottom: 1px solid #ccc;
    }

    &-incoming {
      font-size: 12px;
      text-transform: uppercase;
      font-weight: bold;
      border-top: 1px solid #ccc;
    }

    &-outbound {
      color: red;
      font-size: 12px;
      text-transform: uppercase;
    }

    &-costing {
      color: #267ec3;
      font-size: 12px;
      text-transform: uppercase;
    }
    .panel-labels::v-deep {
      .v-expansion-panel:nth-child(even) {
        background: #eeeeee;
      }
       .v-expansion-panel-header {
        min-height: 5px;
        position: relative;
        text-align: left;
        align-items: flex-start;
        display: block;
        @extend %default;
        padding:  5px 5px;
      }
    
        .v-expansion-panel-content__wrap { // dentro
          align-self: flex-start;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          text-align: left;
          font-size: 12px;
          color: #9797ad;
          padding: 0 !important;
          h4 {
            padding-left: 40px;
            width: 100%;
            text-align: center;
            border-bottom: 1px solid #ccc;
            &:nth-child(odd) {
              background: #eeeeee;
            }
          }
        }
      .v-expansion-panel-header {
        border-bottom: 1px solid #ccc;
        h4 {
          order: 2;
          text-align: left;
          padding-left: 20px;
          text-transform: uppercase;
        }

        h5 {
          text-align: left;
          padding-left: 20px;
        }

        .v-expansion-panel-header__icon {
          order: 1;
          position: absolute;
          left: 0;
          top: 15%;
        }
      }
    }

    .panel-labels-niveis::v-deep {     
    .v-expansion-panel { // 1 nivel
      .v-expansion-panel-header__icon {
        padding-left: 8px;
      }
      .v-expansion-panel-header {
        padding-left: 10px !important;
      }

      .v-expansion-panel-content > .v-expansion-panel-content__wrap h4 { // receita e custeio nivel 2
        &:nth-child(even) {
          background: #eeeeee;
        }
      }

        margin: 0;
        .v-expansion-panel-content .v-expansion-panel  { // 2 labels
          &:nth-child(even) {
             background: #eeeeee;
          }
        }
        .v-expansion-panel-content > .v-expansion-panel-content__wrap{
          .v-expansion-panel-content  > .v-expansion-panel-content__wrap{ // 3 labels
            h4{
              &:nth-child(odd) {
                background: white;
              }
            }
          }
        }
      }
      .v-expansion-panel-header {
          min-height: 20px;
          position: relative;
          text-align: left;
          align-items: flex-start;
          display: block;
          @extend %default;
          padding:  5px 5px;
          &.custeio {
            // background: #f9e2c0;
          }
      }
    
      .v-expansion-panel-content__wrap { // dentro
        align-self: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        text-align: left;
        font-size: 12px;
        color: #9797ad;
        padding: 0 !important;
        border-bottom: 1px solid #ccc;
        h4 {
          padding-left: 40px;
        }
      }
      .v-expansion-panel-header {
        border-bottom: 1px solid #ccc;
        h4 {
          order: 2;
          text-align: left;
          padding-left: 20px;
          text-transform: uppercase;
        }

        h5 {
          text-align: left;
          padding-left: 20px;
        }

        .v-expansion-panel-header__icon {
          order: 1;
          position: absolute;
          left: 0;
          top: 15%;
        }
      }
    }

    .panel-dados::v-deep {
      .v-expansion-panel .v-expansion-panel-content .v-expansion-panel-content__wrap h4 { // receitas e custeio striped valores
        &:nth-child(even) {
          background: #eeeeee;
        }
      }

      .v-expansion-panel { // 2 nivel de valores
        margin: 0;
        border-bottom: 1px solid #ccc;
        &:nth-child(even) {
          background: #eeeeee;
        }
        .v-expansion-panel-content  > .v-expansion-panel-content__wrap{
          padding: 0;
          h4 {
            padding-right: 15px;
            width: 100%;
            text-align: right;
          }
          
            .v-expansion-panel-content  > .v-expansion-panel-content__wrap{
              h4{
                text-align: right;
                &:nth-child(odd) {
                  background: white;
                }
              }
            }
        }
      }
      
       .v-expansion-panel-header {
        min-height: 20px;
        padding: 5px 0;
        position: relative;
        text-align: left;
        align-items: flex-start;
        display: block;
        font-size: 12px;
        .v-expansion-panel-header__icon {
          display: none;
        }
        &.custeio {
          // background: #f9e2c0;
        }
      }
      
      // Tabs interna
        .v-expansion-panel-content__wrap {
          align-self: flex-start;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          text-align: left;
          padding-right: 0;
          color: #de890a;
          font-size: 12px;
          padding-bottom: 0;
          padding-left: 35px;
          border-top: 1px solid #ccc;
          border-bottom: 1px solid #ccc;
          width: 100%;
          &:last-child {
            border-bottom:none;
          }
        }

      .v-expansion-panel-header {
        h4 {
          order: 2;
          text-align: left;
        }
      }
    }
  }

  @media print {
    .container, .container--fluid {
      min-width: 100% !important;
      max-width: 100% !important;
      margin: 0 !important;
      padding: 0 !important;
    }

    .v-card__text {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
  .titulo span {
    font-weight: bold;
    cursor: pointer;
  }
</style>