<template>
  <div class="select-card-content d-flex align-center justify-center" >
    <div class="select-date justify-center" v-if="!disabled">
      <!-- Voltar mês -->
      <v-icon @click="voltar()" class="select-date-back no-print"> arrow_back_ios </v-icon>
      <div class="select-date-text pt-3 mb-3"> 
          <v-menu open-on-hover offset-y >
              <template v-slot:activator="{ on, attrs }">
                <label style="background: white; color: #757575" v-bind="attrs" v-on="on" > {{year.text }} </label>
              </template>
              <v-list>
                <v-list-item v-for="(item, index) in optionSelect" :key="index" >
                    <v-list-item-title @click="resetYears('atual')" class="text-center"> {{ item }} </v-list-item-title>
                </v-list-item>
              </v-list>
          </v-menu>
      </div>
      <!-- Avançar mês -->
      <v-icon class="select-date-next no-print" @click="proximo()">  arrow_forward_ios  </v-icon>
    </div>
    
    <v-progress-circular v-else class="loader" style="position: relative; top: 8px" indeterminate color="primary" />

  </div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'DateArrowss',
  props:['disabled'],
  data () {
    return {
      optionSelect: ['atual'],
      year: {
        text: moment().format('YYYY'),
        year: moment().format('YYYY')
      },
      atual: 0
    }
  },
  mounted () {
    this.year = {
        text: moment().format('YYYY'),
        year: moment().year(),
      }
  },
  methods: {
    years (increment = false) {
      this.atual = increment ? this.atual + 1 : this.atual - 1
      let ano = moment().year()
      this.year.text = moment().year(ano + this.atual).format(`YYYY`)
      this.year.year = ano + this.atual
      this.$emit('input', this.year)
    },
    proximo () {
      this.years(true)
    },
    voltar () {
      this.years(false)
    },
    resetYears () {
      this.atual = 0
      this.year.text = moment().year()
      this.year.year = this.years.text
      this.$emit('input', this.year)
    }
  }
}
</script>
<style lang="scss" scoped>
  @import '~@/assets/styles/helpers/_variables.scss';
  .select-date {
    width: 100%;
    display: flex;
    // flex-flow: center;
    flex-wrap: nowrap;
    // justify-content: center;
    font-size:18px;
    &-title {
      color: #1cb4ff;
      font-size: 18px;
    }
    &-back,
    &-next {
      padding: 0 10px;
      cursor: pointer;
      &:focus::after{
        background: white;
      }
    }
  }
</style>

  